import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText, MDBCardImage } from 'mdb-react-ui-kit';
import logo from './logo.svg';
import splash from './splash.jpg';
import './App.css';

function App() {
  return (
    <MDBCard style={{ width: '18rem' }}>
      <MDBCardImage src={splash} alt='' position='top' />
    </MDBCard>
  );
}

export default App;
